import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api' : 'https://staging-tracking-v2.merchize.com/api'
const api = createAPIServices({baseUrl})

export const getMapping = data => {
    const {page, limit, ...body} = data
    return api.makeAuthRequest({
        url : `tracking-status`,
        method: 'POST',
        data : body,
        params : {page, limit}
    })
}

export const deleteMapping = data => {
    return api.makeAuthRequest({
        url: 'tracking-status/mapping',
        method : 'DELETE',
        data : data
    })
}

export const createMapping = data => {
    return api.makeAuthRequest({
        url : 'tracking-status/mapping',
        method : 'POST',
        data : data
    })
}

export const editMapping = data => {
    return api.makeAuthRequest({
        url : 'tracking-status/mapping',
        method : 'PUT',
        data : data
    })
}